import PropTypes from 'prop-types';
import React from 'react';

const Hero = (props) => (
  <div className='hero'>
    <h1 className='hero__title'>{props.title}</h1>
    {props.description ? 
      <p className='hero__description'>{props.description}</p>
      : null}
    
    {props.children ?
      <div className='hero__children'>{props.children}</div>
      : null}
  </div>
);

Hero.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

export default Hero;

import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/hero';

const DetailsPage = ({ data }) => {
  const contributors = data.markdownRemark.frontmatter.contributors.join(', ');

  return (
    <Layout>
      <Hero title={data.markdownRemark.frontmatter.title}>
        {data.markdownRemark.frontmatter.tags && data.markdownRemark.frontmatter.tags.map((tag, index) => (
          <div className="tag" key={index}>{tag}</div>
        ))}
      </Hero>

      <section className="details-page-layout">
        <aside className="table-of-contents-container">
          <div className="table-of-contents">
            <h2>Table of contents</h2>
            <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.tableOfContents }} />
          </div>
        </aside>

        <article className="main-content">
          <div className="m-rich-text">
            <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
            <address rel='author'>Contributors: {contributors}</address>
          </div>
        </article>
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query GetArchitectureDetails($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      frontmatter {
        tags
        title
        contributors
      }
      tableOfContents(absolute: false)
      html
    }
  }
`;

export default DetailsPage;
